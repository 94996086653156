@import '../../_styles.scss';

.Frame {
    @include flexContainer(
        $align: center,
        $direction: column,
        $justify: space-between,
        $position: absolute
    );
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-width: 810px;
    max-height: 630px;
    width: 100%;
    height: calc(100% - 70px);
    padding: 50px;
    font-family: "Felixti";
    color: $mainColor;

    @media (max-width: 540px) {
        max-height: calc(100% - 70px);
        margin-bottom: 60px;
        margin-top: 10px;
        padding: 10px 15px;
    }

    @media (min-height: 700px) {
        padding: 30px 15px;
    }

    &__box {
        @include imgBg();
    }

    > div {
        max-width: 100%;
    }
}

.notifyTransition {
    &-enter,
    &-appear {
        opacity: 0.01;
    }

    &-enter-active,
    &-appear-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0.01;
        transition: opacity 100ms ease-in;
    }
}