.Footer {
    height: 50px;
    box-sizing: border-box;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #000;

    &__link {
        margin: 0 10px;
        color: #dbc098;
        text-transform: uppercase;
        font-size: 10px;
        font-family: 'Times New Roman', Times, serif;

        @media (max-width: 359px) {
            font-size: 9px;
        }
    }
}
