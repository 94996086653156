$mainColor: #f3edd1;

@mixin imgBg() {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: fill;
    position: absolute;
}

@mixin flexContainer(
    $direction: row,
    $justify: center,
    $position: relative,
    $align: center,
) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
    position: $position;
    box-sizing: border-box;
    width: 100%;
}