@import '../../_styles.scss';

.FooterAlt {
    @media (max-width: 540px) {
        @include flexContainer($direction: column);
    }

    &__link {
        color: grey;
        font-size: 16px;
        font-family: 'Times New Roman', Times, serif;

        @media (max-width: 540px) {
            margin: 5px 0;
        }
    }

    &__delimiter {
        margin: 0 5px;

        @media (max-width: 540px) {
            display: none;
        }
    }
}