@import '../../_styles.scss';

.Loading {
    @include flexContainer();
    width: 90%;
    margin: 0 5%;

    @media (max-width: 540px) {
        height: 48px;
    }

    &__item {
        height: 60px;

        @media (max-width: 540px) {
            height: 36px;
        }

        &--left {
            background: $mainColor;
        }

        &--right {
            background: grey;
        }
    }
}