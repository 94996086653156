@import '../../_styles.scss';

.Steps {
    @include flexContainer();
    margin-top: 20px;
    
    &__text {
        margin: 0 25px;
        font-size: 54px;
        font-weight: bold;
        min-width: 300px;
        text-align: center;

        @media (max-width: 540px) {
            margin: 0 5px;
            min-width: 160px;
            font-size: 24px;
        }
    }

    &__arrow {
        padding: 0 15px;
        cursor: pointer;
        &__img.disabled {
           opacity: .5; 
        }
    }
}