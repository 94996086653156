@import '../../_styles.scss';

.Buttons {
    @include flexContainer();
    padding: 0 15px;

    @media (max-width: 540px) {
        flex-direction: column;
        padding: 0 5px;
    }

    &__item {
        @include flexContainer();
        max-width: 50%;
        margin: 0 10px;
        width: 320px;
        height: 70px;
        cursor: pointer;

        @media (max-width: 540px) {
            height: 48px;
            margin: 10px 0;
            max-width: 92%;
        }

        &__img {
            @include imgBg();
        }

        &__text {
            font-size: 24px;
            position: relative;

            @media (max-width: 540px) {
                font-size: 20px;
            }
        }
    }
}