@import '../../_styles.scss';

.Text {
    position: relative;
    text-align: center;
    padding: 10px 20px;
    overflow: overlay;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 540px) {
        padding: 10px 5px;
    }

    &::-webkit-scrollbar-button {
        background-repeat: no-repeat;
        width: 5px;
        height: 0px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparentize(#ecedee, 0.7);
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0px;
        border-radius: 0px;
        background-color: transparentize(#6dc0c8, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: transparentize(#56999f, 0.5);
    }

    &::-webkit-resizer {
        background-repeat: no-repeat;
        width: 4px;
        height: 0px;
    }

    &::-webkit-scrollbar {
        width: 4px;
    }

    &__item {
        font-size: 36px;
        margin: 35px 0;
        width: 100%;

        @media (max-width: 540px) {
            margin: 25px 0;
            font-size: 20px;
        }

        &--big {
            font-weight: bold;
            font-size: 42px;

            @media (max-width: 540px) {
                font-size: 24px;
            }
        }
    }
}

.browser {
    @include flexContainer();

    &__img {
        width: 96px;
        height: 96px;
        margin-right: 20px;

        @media (max-width: 540px) {
            width: 48px;
            height: 48px;
        }
        &--flag {
            width: auto;
        }
    }
}